import { isArray, isEmpty, isNil, isNumber, isRegExp, isString, keys, mapKeys, merge } from 'lodash-es';
import moment, { Moment } from 'moment';
import * as uuid from 'uuid';
import { Observable } from 'rxjs';
import xss from 'xss';

import type { ValidatorFn } from '@angular/forms';

import { IValidationErrors } from './models';

export type IValidatorInput<T = unknown> = { value: T };

export type IValidatorFunc<T = unknown> = (input: IValidatorInput<T>) => IValidationErrors | null;

export type IAsyncValidatorFunc<T = unknown> = (input: IValidatorInput<T>) => Observable<IValidationErrors | null> | Promise<IValidationErrors | null>;

export const OUT_OF_AGE_DATE = moment().subtract(18, 'years');

export const MINIMUM_PASSWORD_LENGTH = 12;
export const MAXIMUM_PASSWORD_LENGTH = 64;

export const CARD_HOLDER_CHARACTER_ALLOWED = /[A-Za-zÀ-ž\u05D0-\u05EA- '.]/u;

export const CARD_HOLDER_ALL_CHARACTERS_ALLOWED = new RegExp(`^${ CARD_HOLDER_CHARACTER_ALLOWED.source }+$`, 'u');

// @link https://github.com/angular/angular/blob/5adfe8ef240c1e6a4c39eaa40f286177736239bc/packages/forms/src/validators.ts#L123
const EMAIL_REGEXP
	// eslint-disable-next-line require-unicode-regexp
	= /^(?=.{1,254}$)(?=.{1,64}@)[\w!#$%&'*+/=?^`{|}~-]+(?:\.[\w!#$%&'*+/=?^`{|}~-]+)*@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)*$/;

/**
 * Notes:
 * - maximum length of each domain part (label) is 63 symbols
 * - last domain label must start with letter
 * - \p{L} regexp class matches any Unicode letter (to allow unicode domains)
 *     - fully supported https://caniuse.com/mdn-javascript_builtins_regexp_property_escapes
 *
 * Regexp and some examples: https://regex101.com/r/0H3wpz/5
 */
const URL_REGEXP = String.raw`(?:www\.)?(?:[\p{L}\d\-]{1,63}\.)+\p{L}[\d\p{L}]{0,62}(?:[\/?][\w#%&()+.\/:=?@~-]*)?`;

/**
 * Provides a set of custom validators.
 *
 * A validator is a function that processes a {@link FormControl} or collection of
 * controls and returns a map of errors. A null map means that validation has passed.
 *
 * ### Example
 *
 * ```typescript
 * var loginControl = new FormControl("", Validators.required)
 * ```
 */
export class Validators {

	static email(this: void, config?: { dontRequireTopLevelDomain: boolean }): IValidatorFunc {
		return ({ value }: IValidatorInput): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!isString(value))
				throw new Error('`email` validator expects string to be validated');

			const result: IValidationErrors | null = EMAIL_REGEXP.test(value) ? null : { email: true };

			if (result !== null)
				return result;

			if (config?.dontRequireTopLevelDomain)
				return null;

			const [ , domain ] = value.split('@');

			// Check domain is non-root
			return domain.includes('.')
				? null
				: { email: { value } };
		};
	}

	/**
	 * Validator that requires controls to have a non-empty and without whitespaces value.
	 */
	static required(this: void, { value }: IValidatorInput): IValidationErrors | null {
		return Validators.isEmptyValue(value) || (isString(value) && !value.trim())
			? { required: true }
			: null;
	}

	static defined(this: void, { value }: IValidatorInput): IValidationErrors | null {
		return isNil(value) ? { required: true } : null;
	}

	static string(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		return isString(value)
			? null
			: { string: { value } };
	}

	static boolean(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (isNil(value))
			return null; // Don't validate empty values to allow optional controls

		return (value === true || value === false)
			? null
			: { boolean: { value } };
	}

	static validMoment(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (isNil(value))
			return null; // Don't validate empty values to allow optional controls

		return (moment.isMoment(value) && value.isValid())
			? null
			: { validMoment: { value } };
	}

	static requiredArray(this: void, { value }: IValidatorInput): IValidationErrors | null {
		return isEmpty(value)
			? { required: { value } }
			: null;
	}

	static pascalCase(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`pascalCase` validator expects string to be validated');

		return (/\s|^[a-z]/ug).test(value)
			? { pascalCase: { value } }
			: null;
	}

	static beforeDate<T = Moment>(this: void, maxMoment: Moment): IValidatorFunc<T> {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!moment.isMoment(value))
				throw new Error('`beforeDate` validator expects moment to be validated');

			return value.isBefore(maxMoment)
				? null
				: {
					beforeDate: {
						required: maxMoment.format('LLL'),
						value,
					},
				};
		};
	}

	static afterDate<T = Moment>(this: void, minMoment: Moment): IValidatorFunc<T> {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!moment.isMoment(value))
				throw new Error('`afterDate` validator expects moment to be validated');

			return value.isAfter(minMoment)
				? null
				: {
					afterDate: {
						required: minMoment.format('LLL'),
						value,
					},
				};
		};
	}

	static afterNow<T = Moment>(this: void): IValidatorFunc<T> {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!moment.isMoment(value))
				throw new Error('`afterDate` validator expects moment to be validated');

			const now = moment();

			return value.isAfter(now)
				? null
				: {
					afterDate: {
						required: now.format('LLL'),
						value,
					},
				};
		};
	}

	static inList<T>(this: void, list: T[]): IValidatorFunc<T> {
		return (input): IValidationErrors | null => {
			const { value } = input;

			return Validators.isEmptyValue(value)
				? null
				: Validators.inListStrict(list)(input);
		};
	}

	static inListStrict<T>(this: void, list: T[]): IValidatorFunc<T> {
		return ({ value }): IValidationErrors | null => list.includes(value)
			? null
			: { inList: { value, required: list.join(', ') } };
	}

	/**
	 * Validator that requires controls to have a non-empty and without whitespaces value.
	 * @param name name of the custom required message key
	 */
	static customRequired(this: void, name: string): IValidatorFunc {
		return (validatable): IValidationErrors | null => Validators.required(validatable)
			? { [`required.${ name }`]: true }
			: null;
	}

	static noZero(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isNumber(value))
			throw new Error('`noZero` validator expects number to be validated');

		return value === 0
			? { noZero: true }
			: null;
	}

	static hasUpperCaseAndLowerCaseCharacter(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		const letters = [ ...<string>value ];
		const hasUpperCaseLetter = letters.some(v => v === v.toUpperCase() && v !== v.toLowerCase());
		const hasLowerCaseLetter = letters.some(v => v === v.toLowerCase() && v !== v.toUpperCase());

		return hasUpperCaseLetter && hasLowerCaseLetter
			? null
			: { hasUpperCaseAndLowerCaseCharacter: true };
	}

	static hasSpecialCharacter(this: void, { value }: IValidatorInput<string>): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		return (/[!"#$%&'()*+,./:;<=>?@[\\\]^_{|}-]/u).test(value)
			? null
			: { hasSpecialCharacter: true };
	}

	static hasLetterCharacter(this: void, { value }: IValidatorInput<string>): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		const hasLetterCharacter = (/\p{L}/u).test(value);

		return hasLetterCharacter ? null : { hasLetterCharacter: true };
	}

	static onlyASCIICharacters(this: void, { value }: IValidatorInput<string>): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		// eslint-disable-next-line no-control-regex
		const hasNonASCIICharacters = (/[^\u{0}-\u{7F}]/u).test(value);

		return hasNonASCIICharacters ? { onlyASCIICharacters: true } : null;
	}

	static noLeadingOrTrailingSpace(this: void, { value }: IValidatorInput<string>): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		const hasLeadingOrTrailingSpace = (/^\s|\s$/u).test(value);

		return hasLeadingOrTrailingSpace ? { noLeadingOrTrailingSpace: true } : null;
	}

	static hasDigitCharacter(this: void, { value }: IValidatorInput<string>): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		const hasDigit = (/\d/u).test(value);

		return hasDigit ? null : { hasDigitCharacter: true };
	}

	static password(this: void): IValidatorFunc<string> {
		const passwordValidator = Validators.compose([
			Validators.hasUpperCaseAndLowerCaseCharacter,
			Validators.hasLetterCharacter,
			Validators.hasDigitCharacter,
			Validators.minLength(MINIMUM_PASSWORD_LENGTH),
			Validators.maxLength(MAXIMUM_PASSWORD_LENGTH),
		])!;

		return (validatable): IValidationErrors | null => {
			if (Validators.isEmptyValue(validatable.value))
				return null; // Don't validate empty values to allow optional controls

			const result = passwordValidator(validatable);

			return result
				? mapKeys(result, (value, key) => `password.${ key }`)
				: null;
		};
	}

	static confirmPassword(this: void, propertyName: string = 'password'): ValidatorFn {
		return (control): IValidationErrors | null => {
			if (Validators.isEmptyValue(control.value))
				return null; // Don't validate empty values to allow optional controls

			if (!control.parent || Array.isArray(control.parent.controls))
				throw new Error('The confirm Password validator expects the control\'s parent to be a FormGroup');

			return control.parent.controls[propertyName].value === control.value
				? null
				: { passwordConfirm: true };
		};
	}

	static safeInteger(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		return Number.isSafeInteger(value)
			? null
			: { safeInteger: { value } };
	}

	/**
	 * Check value contains only digits and nothing more
	 */
	static digits(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		return (/\D/u).test(`${ value }`)
			? { digits: { value } }
			: null;
	}

	static array(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		return Array.isArray(value)
			? null
			: { array: { value } };
	}

	/**
	 * Check value contains only number and nothing more with the decimal separator
	 */
	static number(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		return Number.isNaN(Number(value))
			? { digits: { value } }
			: null;
	}

	static maxDigitsAfterDecimal(this: void, digitsCount: number): IValidatorFunc {
		return ({ value }: IValidatorInput): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			const numericValue = Number(value);

			if (Number.isNaN(numericValue))
				throw new Error('`maxDigitsAfterDecimal` validator expects number to be validated');

			const [ , decimalPart ] = numericValue.toString().split('.');

			return decimalPart && decimalPart.length > digitsCount
				? { maxDigitsAfterDecimal: { value, required: digitsCount } }
				: null;
		};
	}

	static cardHolder(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`cardHolder` validator expects string to be validated');

		return CARD_HOLDER_ALL_CHARACTERS_ALLOWED.test(value)
			? null
			: { ccHolder: null };
	}

	static onlyLettersAndHyphens(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`onlyLettersAndHyphens` validator expects string to be validated');

		return (/[\d!#$%&'()*+,.:;<=>?@[\]^{|}_]/ug).test(value)
			? { noSpecialCharactersOrDigits: true }
			: null;
	}

	/** Allows some specific symbols which could be in names */
	static noSpecialCharactersOrDigits(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`noSpecialCharactersOrDigits` validator expects string to be validated');

		return (/[~$&+:;=?@#|<>^*()%![\]{}\d]/ug).test(value)
			? { noSpecialCharactersOrDigits: null }
			: null;
	}

	static alphanumeric(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`alphanumeric` validator expects string to be validated');

		return ((/\W/ug).test(value))
			? { alphanumeric: null }
			: null;
	}

	/**
	 * Validator that requires controls to have a value of a minimum possible value.
	 */
	static minimum(this: void, required: number): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!isNumber(value))
				throw new Error('`minimum` validator expects number to be validated');

			return value < required
				? { minimum: { required, value } }
				: null;
		};
	}

	static greaterThan(this: void, required: number): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!isNumber(value))
				throw new Error('`greaterThan` validator expects number to be validated');

			return value <= required
				? { greaterThan: { required, value } }
				: null;
		};
	}

	/**
	 * Validator that requires controls to have a value of a maximum possible value.
	 */
	static maximum(this: void, required: number): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!isNumber(value))
				throw new Error('`maximum` validator expects number to be validated');

			return value > required
				? { maximum: { required, value } }
				: null;
		};
	}

	/**
	 * Validator that requires controls to have a value of a minimum length.
	 */
	static minLength(this: void, requiredLength: number): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			const targetValue = isString(value) ? value : String(value);

			const valueLength = targetValue.length;

			return valueLength < requiredLength
				? { minlength: { requiredLength, value } }
				: null;
		};
	}

	/**
	 * Validator that requires controls to have a value of a maximum length.
	 */
	static maxLength(this: void, requiredLength: number): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			const targetValue = isString(value) ? value : String(value);

			const valueLength = targetValue.length;

			return valueLength > requiredLength
				? { maxlength: { requiredLength, value } }
				: null;
		};
	}

	static maxArrayLength(this: void, requiredLength: number): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!isArray(value))
				throw new Error('`maxArrayLength` validator expects array to be validated');

			return value.length > requiredLength
				? { maxArrayLength: { requiredLength, value } }
				: null;
		};
	}

	static minArrayLength(this: void, requiredLength: number): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!isArray(value))
				throw new Error('`minArrayLength` validator expects array to be validated');

			return value.length < requiredLength
				? { minArrayLength: { requiredLength, value } }
				: null;
		};
	}

	// https://en.wikipedia.org/wiki/Plane_(Unicode)#Basic_Multilingual_Plane
	// Note we must use this validator any time string is goes to MySQL database.
	static utf8Bmp(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`utf8Bmp` validator expects string to be validated');

		/**
		 * Note utf8 over BMP requires surrogate code which counts as 2 symbols using string length.
		 * Thus, comparing it with value symbols count (by splitting by symbols to array), we can check
		 * whether there are special symbols or not.
		 */
		return value.length === [ ...value ].length ? null : { utf8Bmp: true };
	}

	static uuid(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`uuid` validator expects string to be validated');

		return uuid.validate(value)
			? null
			: { uuid: true };
	}

	static excessSafeNumber(this: void, enabled: boolean): IValidatorFunc {
		const maxSafeNumberValue = 999_999_999_999.99;

		return ({ value }): IValidationErrors | null => {
			if (!isNumber(value))
				throw new Error('`excessSafeNumber` validator expects number to be validated');

			return enabled && value > maxSafeNumberValue
				? { excessSafeNumber: true }
				: null;
		};
	}

	static ip(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`ip` validator expects string to be validated');

		if ((/((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/gu).test(value))
			return null;

		return { ip: true };
	}

	static url(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`url` validator expects string to be validated');

		return (new RegExp(`^https?:\\/\\/${ URL_REGEXP }$`, 'ug')).test(value)
			? null
			: { url: true };
	}

	static csvInjectionSafe(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`csvInjectionSafe` validator expects string to be validated');

		return (new RegExp('^[^"\'/`<>{}+@]*$', 'ug')).test(value)
			? null
			: { csvInjectionSafe: true };
	}

	static xssSafe(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`xssSafe` validator expects string to be validated');

		return xss(value) === value
			? null
			: { xssSafe: true };
	}

	static noUrl(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`noUrl` validator expects string to be validated');

		return Validators.urlWithOptionalProtocol({ value })
			? null
			: { noUrl: true };
	}

	static urlWithOptionalProtocol(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`urlWithOptionalProtocol` validator expects string to be validated');

		return (new RegExp(`^(https?:\\/\\/)?${ URL_REGEXP }$`, 'ug')).test(value)
			? null
			: { urlWithOptionalProtocol: true };
	}

	static urlWithoutProtocol(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`urlWithoutProtocol` validator expects string to be validated');

		return (new RegExp(`^${ URL_REGEXP }$`, 'ug')).test(value)
			? null
			: { urlWithoutProtocol: true };
	}

	/**
	 * Validator that requires a control to match a regex to its value.
	 */
	static pattern(this: void, pattern: RegExp | string, message?: string): IValidatorFunc {
		const regex = isRegExp(pattern) ? pattern : new RegExp(pattern, 'u');

		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!isString(value) && !isNumber(value))
				throw new Error('`pattern` validator expects string or number to be validated');

			return regex.test(value.toString())
				? null
				: {
					pattern: message ?? { required: regex.source, value },
				};
		};
	}

	/**
	 * No-op validator.
	 */
	static noop(this: void): IValidatorFunc {
		return (): IValidationErrors | null => null;
	}

	/**
	 *
	 * @returns true if the number is of provided length, otherwise false
	 */
	static fixedLength(this: void, requiredLength: number, name?: string): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null;

			if (!isNumber(value) && !isString(value))
				throw new Error('`fixedLength` validator expects string  or number to be validated');

			const valueLength = (value).toString().length;

			return valueLength === requiredLength
				? null
				: { [name ? `fixedLength.${ name }` : 'fixedLength']: { requiredLength, value } };
		};
	}

	/**
	 * Validator that requires each item of the array to match provided rules
	 * @param validators - array of rules which will be applied to each element of the array
	 * @returns
	 */
	static runOverEachArrayItem<TControlValue extends [] = []>(this: void, validators: IValidatorFunc<TControlValue[number]>[]): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (isEmpty(value) || Validators.isEmptyValue(value))
				return null;

			if (!isArray(value))
				throw new Error('`runOverEachArrayItem` validator expects array to be validated');

			const errors = <IValidationErrors[]>
				value.flatMap((arrayItem: TControlValue[number]) => validators.map(validator => validator({ value: arrayItem })));

			return Validators._mergeErrors(errors);
		};
	}

	/**
	 * Compose multiple validators into a single function that returns the union
	 * of the individual error maps.
	 */
	static compose<T = unknown>(
		this: void, validators: (IValidatorFunc<T> | ValidatorFn | null | undefined)[],
	): IValidatorFunc<T> | null {
		const presentValidators = <IValidatorFunc[]>validators.filter(validator => !isNil(validator));

		if (isEmpty(presentValidators))
			return null;

		return validatable => Validators._mergeErrors(
			Validators._executeValidators(validatable, presentValidators),
		);
	}

	static sequence<T = unknown>(
		this: void, validators: (IValidatorFunc<T> | ValidatorFn | null | undefined)[],
	): IValidatorFunc<T> | null {
		const presentValidators = <IValidatorFunc[]>validators.filter(validator => !isNil(validator));

		if (isEmpty(presentValidators))
			return null;

		return validatable => presentValidators.reduce(
			(error: IValidationErrors | null, validator: IValidatorFunc) => error ?? validator(validatable),
			null,
		);
	}

	static composeAsync(this: void, validators: (IAsyncValidatorFunc<any> | null | undefined)[]): IAsyncValidatorFunc<any> | null {
		if (isEmpty(validators))
			return null;

		const presentValidators = validators.filter(validator => !isNil(validator));

		if (isEmpty(presentValidators))
			return null;

		return async validatable => {
			const promises = Validators
				._executeAsyncValidators(validatable, presentValidators)
				// eslint-disable-next-line @typescript-eslint/promise-function-async
				.map(v => Validators._convertToPromise(v));

			const errors = await Promise
				.all(promises);

			return Validators._mergeErrors(errors);
		};
	}

	static isEmptyValue(this: void, value: unknown): value is '' | null {
		return isNil(value) || value === '';
	}

	// eslint-disable-next-line @typescript-eslint/promise-function-async
	private static _convertToPromise(this: void, object: any): Promise<any> {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
		return !!object && typeof object.then === 'function' ? object : object(object);
	}

	private static _executeValidators(this: void, validatable: IValidatorInput, validators: IValidatorFunc[]): any[] {
		return validators.map(v => v(validatable));
	}

	private static _executeAsyncValidators(
		this: void,
		control: IValidatorInput,
		validators: IAsyncValidatorFunc[],
	): any[] {
		// eslint-disable-next-line @typescript-eslint/promise-function-async
		return validators.map(v => v(control));
	}

	private static _mergeErrors(arrayOfErrors: (IValidationErrors | null)[]): IValidationErrors | null {

		const result = arrayOfErrors.reduce(
			(accumulator: IValidationErrors | null, errors: IValidationErrors | null) => errors === null ? accumulator : merge(accumulator, errors),
			{},
		);

		return keys(result).length === 0 ? null : result;
	}
}
